import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import UploadHedgesDialog, { AmountDirection } from './UploadHedgesDialog';
import Page from '../../layout/Page';
import './PartnerManualHedgingPage.scss';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import MapPartnerHedgeData from './MapHedgesData';
import { ManualHedgingMappingResponse, ManualHedgingStatusType } from '@grain/admin-console-api-types';
import colors from '@grain/web-components/styles/colors.scss';
import SectionCard from '@grain/web-components/layout/SectionCard';
import { Data } from 'iconsax-react';
import ExpandableMenuItem from '@grain/web-components/layout/ExpandableMenuItem';
import { ALL_MAPPING_FIELD_GROUPS, FROM_AMOUNT_FIELD_GROUPS } from './mapping-fields';
import { notifyError } from '@grain/web-components/notifications/toasters';

type SummaryStatusType = {
  [key in ManualHedgingStatusType]?: ManualHedgingMappingResponse[];
};
export default function PartnerManualHedgingPage({ fromAmount }: { fromAmount?: boolean }) {
  const [hedgesFile, setHedgesFile] = useState<File>();
  const [uploadedHedgesFileUrl, setUploadedHedgesFileUrl] = useState<string>('');
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [summaryStatus, setSummaryStatus] = useState<SummaryStatusType>();
  const { partnerId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const hedgesFileChosen = (file: File, fileUrl: string) => {
    setUploadedHedgesFileUrl(fileUrl);
    setHedgesFile(file);
  };

  const hedgesFileParseFailed = (e: Error) => {
    setLoading(false);
    clearFiles();
    notifyError(e.message);
  };

  const clearFiles = () => {
    setHedgesFile(undefined);
    setUploadedHedgesFileUrl('');
  };

  useEffect(() => {
    (async function () {
      if (location.state) {
        const { uploadedFile, uploadedFileUrl }: FileUploadedState = location.state as FileUploadedState;
        if (uploadedFile && uploadedFileUrl) {
          hedgesFileChosen(uploadedFile, uploadedFileUrl);
        }
      } else {
        navigate(`/partners/${partnerId}`);
      }
    })();
  }, [location, navigate, partnerId]);

  const onMappingComplete = (mapResults: ManualHedgingMappingResponse[]) => {
    setSummaryStatus(
      mapResults.reduce((sum: SummaryStatusType, currentValue) => {
        if (sum[currentValue.status]) {
          sum[currentValue.status]!.push(currentValue);
        } else {
          sum[currentValue.status] = [currentValue];
        }

        return sum;
      }, {})
    );
  };

  const reloadFile = (file: File, fileUrl: string, amountDirection: AmountDirection) => {
    setSummaryStatus(undefined);
    setIsUploadDialogOpen(false);

    // updating the state for refresh reasons
    const newState = {
      ...location.state,
      uploadedFile: file,
      uploadedFileUrl: fileUrl
    };

    if (amountDirection === AmountDirection.FromAmount) {
      navigate(`/partners/${partnerId}/manual-hedging/fromAmount`, { state: newState });
    } else {
      navigate(`/partners/${partnerId}/manual-hedging`, { state: newState });
    }
  };

  function getSummaryDetails(v: ManualHedgingMappingResponse) {
    return `Row ${v.index} - ${v.response.message || (v.response.hedgeResult ? `created ID: ${v.response.hedgeResult.hedgeId}` : '')}`;
  }

  return (
    <Page
      className="manual-hedging-page"
      title={`Manual Hedging ${fromAmount ? `by from amount` : `by to amount`}`}
      loading={loading}
      subtitle={location.state?.partnerName}
    >
      <CTAButton text="Reload Hedge File" size="small" onClick={() => setIsUploadDialogOpen(true)} />

      {summaryStatus && (
        <SectionCard
          className="map-hedges-data-container"
          title="Upload Status"
          icon={<Data />}
          iconBackgroundColor={colors.secondaryOrange}
        >
          {Object.entries(summaryStatus).map(([state, items]) => (
            <div key={state} className={state}>
              <ExpandableMenuItem title={`${state.toUpperCase()} (total: ${items.length})`} initialExpand={false}>
                {items.map((res) => (
                  <div key={res.index}>{getSummaryDetails(res)}</div>
                ))}
              </ExpandableMenuItem>
            </div>
          ))}
        </SectionCard>
      )}
      {hedgesFile && !summaryStatus && (
        <MapPartnerHedgeData
          partnerId={partnerId!}
          hedgesFileUrl={uploadedHedgesFileUrl}
          hedgesFile={hedgesFile}
          onParseFailed={hedgesFileParseFailed}
          onMappingComplete={onMappingComplete}
          fieldGroups={fromAmount ? FROM_AMOUNT_FIELD_GROUPS : ALL_MAPPING_FIELD_GROUPS}
        />
      )}

      <UploadHedgesDialog
        amountDirection={fromAmount ? AmountDirection.FromAmount : AmountDirection.ToAmount}
        open={isUploadDialogOpen}
        onUploadSucceed={reloadFile}
        onUploadStart={clearFiles}
        partnerId={partnerId!}
        onClose={() => setIsUploadDialogOpen(false)}
      />
    </Page>
  );
}

type FileUploadedState = {
  uploadedFile: File;
  uploadedFileUrl: string;
};
