import React, { useState } from 'react';

import { getDashboardRoleNameByRole } from '@grain/web-utils';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, usePagination, useTable } from '@grain/web-components/table-v2';
import { IconButton } from '@grain/web-components/buttons/IconButton';
import { Trash } from 'iconsax-react';
import { useSelector } from 'react-redux';
import TwoButtonsDialog from '@grain/web-components/dialogs/TwoButtonsDialog';
import { SeparatorRow } from '@grain/web-components/separator-row/SeparatorRow';
import EditPartnerUserDialog from './EditPartnerUserDialog';
import { ReactComponent as EditIcon } from '@grain/web-components/icons/edit.svg';
import { AdminConsoleApiPartnerUser, AdminConsoleRole, AdminConsoleUser, DashboardRole } from '@grain/admin-console-api-types';
import { userSelectors } from '../../../store/userStore';
import { asDateString } from '@grain/web-components/utils/formatting';

type UsersTableProps = {
  users: AdminConsoleApiPartnerUser[];
  onUserDelete?: (user: AdminConsoleApiPartnerUser) => void;
  isFixedLayout?: boolean;
  onUserEdit: (id: string, firstName: string, lastName: string, role: DashboardRole, partnerId: string) => void;
  isEditingPartnerUser: boolean;
  loading: boolean;
};

enum DialogType {
  EditUserRole = 'editUserRole',
  DeleteUser = 'deleteUser'
}

interface PartnerUsersTableGetColumnsParams extends UsersTableProps {
  currentUser: AdminConsoleUser;
  onUserDeleteIconClick: (user: AdminConsoleApiPartnerUser) => void;
  onUserEditIconClick: (user: AdminConsoleApiPartnerUser) => void;
}

export default function PartnerUsersTable(props: UsersTableProps) {
  const [selectedAdminConsoleApiPartnerUser, setSelectedAdminConsoleApiPartnerUser] = useState<AdminConsoleApiPartnerUser | null>(null);
  const [displayedDialog, setDisplayedDialog] = useState<DialogType | null>(null);

  const { users, loading } = props;

  const { paginationProps } = usePagination({
    initialPageSize: 5
  });

  const currentUser = useSelector(userSelectors.getCurrentUser) as AdminConsoleUser;

  const { table } = useTable(
    {
      columns: getColumns({
        ...props,
        currentUser,
        onUserDeleteIconClick: (user) => {
          setSelectedAdminConsoleApiPartnerUser(user);
          setDisplayedDialog(DialogType.DeleteUser);
        },
        onUserEditIconClick: (user) => {
          setSelectedAdminConsoleApiPartnerUser(user);
          setDisplayedDialog(DialogType.EditUserRole);
        }
      }),
      data: users
    },
    paginationProps
  );

  const closeDialog = () => {
    setDisplayedDialog(null);
    setSelectedAdminConsoleApiPartnerUser(null);
  };

  return (
    <>
      <div>
        <Table table={table} loading={loading} isFixedLayout={props.isFixedLayout} />
      </div>

      {selectedAdminConsoleApiPartnerUser != null && displayedDialog == DialogType.DeleteUser && (
        <TwoButtonsDialog
          title="Delete User"
          open={displayedDialog === DialogType.DeleteUser}
          onAccept={() => {
            props.onUserDelete?.(selectedAdminConsoleApiPartnerUser);
            closeDialog();
          }}
          onCancel={closeDialog}
          ctaQuestion="Are you sure that you want to continue with this action?"
        />
      )}

      {selectedAdminConsoleApiPartnerUser != null && displayedDialog == DialogType.EditUserRole && (
        <EditPartnerUserDialog
          onEdit={props.onUserEdit}
          userData={selectedAdminConsoleApiPartnerUser}
          isLoading={props.isEditingPartnerUser}
          open={displayedDialog === DialogType.EditUserRole}
          onClose={closeDialog}
        />
      )}
    </>
  );
}

const getColumns = (params: PartnerUsersTableGetColumnsParams) => {
  const columnHelper = createColumnHelper<AdminConsoleApiPartnerUser>();

  return [
    columnHelper.accessor('email', { header: 'Email Address' }),
    columnHelper.accessor('firstName', { header: 'First Name' }),
    columnHelper.accessor('lastName', { header: 'Last Name' }),
    columnHelper.accessor('role', {
      header: 'User Role',
      cell: (info) => getDashboardRoleNameByRole(info.getValue())
    }),
    columnHelper.accessor('lastLoginAt', {
      header: 'Last Login At',
      cell: (info) => (info.getValue() ? asDateString(info.getValue()) : '')
    }),
    columnHelper.display({
      id: 'actions',
      header: '',
      cell: ({ row }) => {
        const partnerUser = row.original;
        const isNotCurrentUser = params.currentUser.email.toLocaleLowerCase() != row.original.email.toLocaleLowerCase();

        return (
          <div className={'flex flex-row justify-end'}>
            <div style={{ height: '24px', display: 'flex' }}>
              {[AdminConsoleRole.SuperAdmin].includes(params.currentUser.role) && isNotCurrentUser && row.getIsHovered() && (
                <SeparatorRow>
                  <IconButton icon={Trash} onClick={() => params.onUserDeleteIconClick(partnerUser)} />
                  <IconButton onClick={() => params.onUserEditIconClick(partnerUser)}>
                    <EditIcon className="w-5 h-5" />
                  </IconButton>
                </SeparatorRow>
              )}
            </div>
          </div>
        );
      }
    })
  ];
};
